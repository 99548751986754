import "antd/dist/reset.css";
import "./index.css";

import { ConfigProvider, Layout } from "antd";
import { Locale } from "antd/es/locale";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import zhTW from "antd/lib/locale/zh_TW";
import i18next from "i18next";
import { Suspense, useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { Header } from "@/components/header/header";

import { SYSTEM_BLACK_LIST } from "./libs/constants";
import * as models from "./models";
import routes from "./routes";
import {
  IAtomInitState,
  setupStoreageState,
  withInitializeState,
} from "./utils/recoil-utils";

function App() {
  const element = useRoutes(routes);

  const [initState, setInitState] = useState<IAtomInitState[] | undefined>(
    undefined
  );

  const { pathname } = useLocation();

  const getLocaleLang = (lang: string): Locale => {
    switch (lang) {
      case "zhCN":
        return zhCN;
      case "zhTW":
        return zhTW;
      case "en":
        return enUS;
      default:
        return zhCN;
    }
  };

  const [lang, setLang] = useState<Locale>(getLocaleLang(i18next.language));

  useEffect(() => {
    setupStoreageState(models)
      .then(setInitState)
      .catch(() => null);
    const handleLangChange = (val: string) => {
      setLang(getLocaleLang(val));
    };

    i18next.on("languageChanged", handleLangChange);

    return () => {
      i18next.off("languageChanged", handleLangChange);
    };
  }, []);

  if (!initState) return null;

  return (
    <ConfigProvider locale={lang}>
      <RecoilRoot initializeState={withInitializeState(initState)}>
        <Layout style={{ position: "relative", background: "#ffffff" }}>
          {!SYSTEM_BLACK_LIST.includes(pathname) && <Header />}
          <Suspense>{element}</Suspense>
        </Layout>
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default App;
