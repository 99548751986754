/* eslint-disable unicorn/filename-case */
/* eslint-disable camelcase */

export const zhCN = {
  welcome_login: "欢迎登录",
  account: "账号",
  password: "密码",
  remember_pwd: "记住密码",
  login: "登录",
  login_account_required: "必须输入用户名",
  login_pwd_required: "必须输入密码",
  input_keyword_search: "请输入关键字搜索",
  learning_data: "学习数据",
  my_course: "我的课程",
  my_exam: "我的考试",
  more_courses: "更多课程",
  exam_review: "考试评审",
  departmental_course: "部门教程",
  quick_generate_tutorial: "快速生成教程",
  update_tutorial: "更新教程",
  all: "全部",
  pending_participation_exam: "待参加",
  pending_review_exam: "待评审",
  not_passed_exam: "未通过",
  passed_exam: "已通过",
  course_number: "课程编号",
  course_title: "课程名称",
  exam_time: "考试时间",
  exam_duration: "考试时长",
  quiz_creator: "出题人",
  total_question_number: "总题数",
  exam_number: "考试编号",
  remaining_exam_attempts: "剩余考试次数",
  times: "次",
  check_score: "查看成绩",
  start_exam: "开始考试",
  compulsory_course: "必修",
  elective_course: "选修",
  duration: "时长",
  course: "课程",
  pcs: "个",
  minutes: "分钟",
  data_learned: "已学习", // 不確定
  to_learn: "待学习",
  learned: "已学习",
  learning: "学习中",
  history: "历史记录",
  learning_dueation: "学习时间",
  lecturer: "讲师",
  exam_start_time: "考试开始时间",
  exam_end_time: "考试结束时间",
  score: "得分：{{score}}分",
  view_details: "查看详情",
  copy_link: "复制链接",
  failed_grade: "不及格",
  passed_grade: "及格",
  single_choice: "单选",
  multiple_choice: "多选",
  judgment: "判断",
  fill_blank: "填空",
  total_score: "总分：{{totalScore}}分",
  questions_total: "共{{questionCount}}题",
  exam_submission_time: "考卷提交时间",
  exam_paper_number: "考卷编号",
  number_of_associated_course: "关联课程编号",
  exam_marker: "评卷人",
  number_of_questions_to_be_reviewed: "待评审题数",
  review_status: "评审状态",
  operation: "操作",
  to_be_reviewed: "待评审",
  reviewed: "已评审", // 未确定
  distributed: "已派发", // 未确定
  review: "评审",
  department: "部门",
  position: "职位",
  nameOfViewer: "观看人姓名",
  organizationOfTheViewer: "观看人所属组织",
  viewingStatus: "观看状态",
  viewingTime: "观看时间",
  question_and_answer: "问答",
  i_posted: "我发布的",
  name: "名称",
  description: "描述",
  personnel_scope: "人员范围",
  shelf_time: "上架时间",
  courseware_upload: "课件",
  click_or_drag: "点击或将文件拖拽到这里上传",
  extension_support: "支持扩展名",
  cancel: "取消",
  confirm: "确认",
  organizational_structure: "组织架构",
  selected_person: "已选人员",
  message_center: "消息中心",
  message_list: "消息列表",
  unread_message: "未读消息",
  read_message: "已读消息",
  on_the_shelf: "上架中",
  removed_from_shelves: "已下架",
  unwatched: "未观看",
  watched: "已观看",
  viewing_record: "观看记录",
  my_viewing_record: "我的观看记录",
  delete: "删除",
  modify: "修改",
  publisher: "发布者",
  viewing_time: "观看时间",
  name_of_viewer: "观看人姓名",
  organization_of_the_viewer: "观看人所属组织",
  viewing_status: "观看状态",
  tutorial_staff_watches_the_record: "教程人员观看记录",
  course_introduction: "课程简介",
  more: "更多",
  course_evaluation: "课程评价",
  correctAnswerMessage: "恭喜你，回答正确！",
  wrongAnswer: "很遗憾，回答错误！",
  yourAnswer: "您的答案：{{yourAnswer}}",
  correctAnswer: "标准答案：{{correctAnswer}}",
  answerAnalysis: "答案解析：{{answerAnalysis}}",
  comment: "评语：{{comment}}",
  mark: "评分：{{score}}分",
  remainingTime: "剩余时间：",
  submit: "提交",
  submit_failed: "提交失败，请重试！",
  submit_success: "提交成功！",
  viewing_history: "观看历史记录",
  courseware_name: "课件名称",
  courseware_number: "课件编号",
  dept_course: "部门教程",
  dept_tutorials_number: "部门教程编号",
  my_dept_tutorials: "我学习的部门教程",
  course_description: "课程描述",
  tutorial_publish: "教程发布",
  tutorial_description: "教程简介",
  copy: "复制",
  copy_success: "复制成功",
  copy_failed: "复制失败",
  i_learned: "我学习的",
  multiplier: "倍速",
  clarity: "清晰度",
  whether_to_delete_data: "是否删除数据",
  yes: "是",
  no: "否",
  file_type_not_supported: "仅支持MP4、MOV、JPG、PNG、PDF格式",
  student_count: "学员总数",
  course_instructor: "课程讲师",
  learning_progress: "学习进度",
  my_pending_courses: "我的待学习课程",
  dept_course_number: "部门课程编号",
  join_course: "是否参与课程",
  course_expired: "课程已超出可学习时间，无法进行学习",
  no_tutorials: "暂无教程",
  upload_type_tips: "只能上传同一类附件，且MP4和PDF只能上传一份",
  examination_title: "考试名称", // 不確定
  paper_number: "考卷编号", // 不確定
  submitter: "提交人", // 不确定
  submission_time: "提交时间", // 不確定
  distribution_success: "成绩派发成功", // 不確定
  distribute: "派发成绩", // 不確定
  attachment: "附件",
  confirm_exit_exam: "是否退出考试？",
  clear_unread: "一键已读",
  scan_qr_code: "请扫描以上二维码填写课程评价",
  distribution_select_results: "请选择要派发的成绩", // 不确定
  pending_examination: "待审批考试", // 不确定
  test_paper_submission_time: "考卷提交时间", // 不確定
  answer: "答案", // 不確定
  review_score: "评分", // 不確定
  points: "分", // 不確定
  review_remark: "评语", // 不確定
  review_remark_empty: "评语不能为空", // 不确定
  review_points_empty: "评分不能为空", // 不确定
  tutorials: "教程",
  tutorials_number: "教程编号",
  logout: "退出",
  echo_avatar: "Echo Avatar",
  multilingual_version: "多语言版本",
  start_make: "开始制作",
  sure_make: "确认制作",
  cancel_making: "关闭",
  cancel_make: "取消制作",
  language: "语种",
  tone: "音色",
  make_state: "制作状态",
  speech_draft: "讲稿",
  download: "下载",
  try_listen: "试听",
  template_download: "模版下载",
  output_language_speech_color_selection: "输出语种语音色选择",
  original_language: "原始语种",
  translation_language: "转译语种",
  video_duration: "视频时长",
  video_name: "视频名称",
  ea_note: "注：如需克隆音色，请到Echo Avatar客户端制作（请使用同一账号登录）",
  ea_tooltip: "讲稿可自行选择是否导入；如不导入，系统将对视频中的音频进行人工智能识别，智能生成讲稿；如导入，系统将按照讲稿内容进行多语种版本制作。",
  total_video_duration: "视频总时长",
  original: "原文",
  translation: "译文",
  word_count: "字数",
  back: "返回",
  sure: "确定",
  preview: "预览",
  batch_download: "批量下载",
  multilingual_translation: "多语言转译",
  ea_hash: "Hash",
  making: "制作中",
  please_select_the_original_language: "请选择原始语种",
  please_select_language: "请选择语种",
  please_select_a_tone: "请选择音色",
  version: "版本号",
  whether_to_cancel_production: "是否取消制作",
};
