import "./index.css";

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { LANGUAGE } from "@/libs/constants";
import { localGet, localSet } from "@/utils/storage";

import styles from "./header.module.less";

export interface IMenuItem {
  label: string;
  key: string;
}

const findLabelByKey = (key: string) => {
  let lang = "";

  switch (key) {
    case "zhTW":
      lang = "中文繁體";
      break;
    case "zhCN":
      lang = "中文简体";
      break;
    case "en":
      lang = "English";
      break;
    default:
      break;
  }

  return lang;
};

export const LanguageSwitching: FC = () => {
  const items: ItemType[] = [
    {
      label: "中文简体",
      key: "zhCN",
    },
    {
      label: "中文繁體",
      key: "zhTW",
    },
    {
      label: "English",
      key: "en",
    },
  ];

  const [langLabel, setLangLabel] = useState(() => {
    if (localGet(LANGUAGE)) {
      return findLabelByKey(localGet(LANGUAGE));
    } else if (items[0] && items[0].key) {
      const key = items[0].key.toString();

      return findLabelByKey(key);
    } else {
      return "中文简体";
    }
  });

  const { i18n } = useTranslation();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    i18n.changeLanguage(e.key);
    setLangLabel(findLabelByKey(e.key));
    localSet(LANGUAGE, e.key);
  };

  const dropdownRender = (node: ReactNode) => {
    return <div className="language-switching-dropdown">{node}</div>;
  };

  return (
    <Dropdown
      menu={{
        items: items,
        onClick: handleMenuClick,
      }}
      className={styles["languageSwitching"]}
      dropdownRender={dropdownRender}
      placement="bottom"
    >
      <Button>
        <Space>
          {langLabel || ""}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
